import React from 'react';
import { toast } from 'react-toastify';

export const successNotification = options => {
    const { time = 3, message = 'Success!', title = 'FluentCloud' } = options;
    if (options.id) {
        // In some odd cases the same noticication can be called twice
        // If you only want one to be shown, you can give the notification an id
        if (!toast.isActive(options.id)) {
            toast.success(
                <div>
                    <h3 >{title}</h3>
                    <p>{message}</p>
                </div>,
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: time && time * 1000,
                    toastId: options.id,
                    className: 'toast-success',
                },
            );
        } else {
            return;
        };
    } else {
        toast.success(
            <div>
                <h3 >{title}</h3>
                <p>{message}</p>
            </div>,
            {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: time && time * 1000,
                className: 'toast-success',
            },
        );
    }
};

export const errorNotification = options => {
    const { time = 10, message = 'Error!', title = 'FluentCloud' } = options;
    if (options.id) {
        if (!toast.isActive(options.id)) {
            toast.error(
                <div>
                    <h3 >{title}</h3>
                    <p>{message}</p>
                </div>,
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: time && time * 1000,
                    toastId: options.id,
                    className: 'toast-error',
                },
            );
        } else {
            return;
        };
    } else {
        toast.error(
            <div>
                <h3 >{title}</h3>
                <p>{message}</p>
            </div>,
            {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: time && time * 1000,
                className: 'toast-error',
            },
        );
    };
};

export const infoNotification = options => {
    const { time = 3, message = 'It happened', title = 'FluentCloud' } = options;
    toast.error(
        <div>
            <h3 >{title}</h3>
            <p>{message}</p>
        </div>,
        {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: time * 1000,
        },
    );
};

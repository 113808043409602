import request from 'superagent';

import {
    GET_HTTP,
    POST_HTTP,
    PATCH_HTTP,
    PUT_HTTP,
    DELETE_HTTP,
    CHANGE_TENANT,
    INVALIDATE_HTTP_DATA,
    HTTP_PENDING,
    HTTP_FAILURE,
    HTTP_SUCCESS,
    SMS_SEND_FAILURE,
    SMS_SEND_SUCCESS,
    CORE_NAMESPACE,
    MASTER_NAMESPACE,
    REPORTS_NAMESPACE,
    REPORTS_NAMESPACE_V2,
    SMS_NAMESPACE,
    USERPORTAL_NAMESPACE,
    CALL_NAMESPACE,
    GET_NEXT_PAGE,
    UPDATE_PAGE_NUMBER,
    NEXT_PAGE_SUCCESS,
} from '../actions/typeConstants';

import { errorNotification } from 'actions/notifications';

const fluentcloudAPI = store => next => action => {
    const token = app.token.attributes;
    const JWT = token.token && token.token.length > 40 ? `bearer ${token.token}` : `session ${token.token}`;

    // Pass all actions through by default
    next(action);

    switch (action.type) {

        case CHANGE_TENANT:
        case INVALIDATE_HTTP_DATA:
            return null;

        case GET_HTTP:

            if (true) {
                const {
                    auditHistory,
                    reqAction,
                    reqObject,
                    reportName,
                    reportPage,
                    loginData,
                } = action.requestData;
                let reqQuery = action.requestData.reqQuery;

                let page = action.page ? action.page : false;

                // If we do not have an object, lets set pending to true
                if (!reqObject) {

                    next({
                        type: HTTP_PENDING,
                        storeKey: action.storeKey || reqAction,
                        reportName,
                    });

                }

                // let's build the url string
                const url = createUrl(action);

                request
                    .get(url)
                    .set('Authorization', JWT)
                    .use(async (req) => {
                        var tokenUpdated = await app.shouldUpdateToken();
                            if(tokenUpdated){
                                console.log("[[INFO]http.request] token has been updated for: GET_HTTP")
                                req.set('Authorization', `bearer ${app.token.get('token')}`);
                            }
                        })
                    .withCredentials()
                    .end((error, res) => {

                        if (error) {

                            verifyAuthenticated(error);

                            // in case there is any error, dispatch an action containing the error
                            return next({
                                type: HTTP_FAILURE,
                                storeKey: action.storeKey || reqAction,
                                reqType: 'GET',
                                error,
                                res,
                            });
                        }

                        if (page) {
                            let nextPage = page + 1;
                            next({
                                type: UPDATE_PAGE_NUMBER,
                                storeKey: 'page', // Override the key that will be used in the store (instead of the action)
                                page: nextPage,
                            });
                        }

                        // Once data is received, dispatch an action telling the application
                        // that data was received successfully, along with the parsed data
                        next({
                            type: HTTP_SUCCESS,
                            storeKey: action.storeKey || reqAction,
                            reqType: 'GET',
                            data: res.body,
                            reqObject,
                            auditHistory,
                            reportName,
                            reportPage,
                            loginData,
                        });
                    });
            }

            break;

        case GET_NEXT_PAGE:

            if (true) {

                const {
                    reqAction,
                    reqObject,
                    reqObject2,
                    reqQuery,
                } = action.requestData;

                //why undefined?  Because I'm not sure how the api will react
                let page = action.page ? action.page : undefined;

                next({
                    type: HTTP_PENDING,
                    storeKey: action.storeKey || reqAction,
                });
                // let's build the url string
                const url = createUrl(action);

                request
                    .get(url)
                    .withCredentials()
                    .set('Authorization', JWT)
                    .use(async (req) => {
                        var tokenUpdated = await app.shouldUpdateToken();
                        if(tokenUpdated){
                            console.log("[[INFO]http.request] token has been updated for: GET_NEXT_PAGE")
                            req.set('Authorization', `bearer ${app.token.get('token')}`);
                        }
                      })
                    .end((error, res) => {

                        if (error) {
                            verifyAuthenticated(error);

                            // in case there is any error, dispatch an action containing the error
                            return next({
                                type: HTTP_FAILURE,
                                storeKey: action.storeKey || reqAction,
                                reqType: 'GET',
                                error,
                                res,
                            });
                        }

                        if (res.body.data.length > 0) {
                            let nextPage = page + 1;

                            next({
                                type: UPDATE_PAGE_NUMBER,
                                storeKey: 'page', // Override the key that will be used in the store (instead of the action)
                                page: nextPage,
                            });
                        }

                        // Once data is received, dispatch an action telling the application
                        // that data was received successfully, along with the parsed data
                        next({
                            type: NEXT_PAGE_SUCCESS,
                            storeKey: action.storeKey || reqAction,
                            reqType: 'GET',
                            data: res.body,
                        });
                    });
            }
            break;

        case PATCH_HTTP:

            if (true) {

                const {
                    reqAction,
                    reqObject,
                    reqObject2,
                    reqQuery,
                    reqBody,
                } = action.requestData;

                // If we have no object, lets set pending to true
                if (!reqObject) {
                    next({
                        type: HTTP_PENDING,
                        storeKey: action.storeKey || reqAction,
                    });
                }

                // let's build the url string
                const url = createUrl(action);

                request
                    .patch(url)
                    .withCredentials()
                    .set('Authorization', JWT)
                    .use(async (req) => {
                        var tokenUpdated = await app.shouldUpdateToken();
                        if(tokenUpdated){
                            console.log("[[INFO]http.request] token has been updated for: PATCH_HTTP")
                            req.set('Authorization', `bearer ${app.token.get('token')}`);
                        }
                      })
                    .send(reqBody)
                    .end((error, res) => {
                        if (error) {
                            verifyAuthenticated(error);

                            // in case there is any error, dispatch an action containing the error
                            return next({
                                type: HTTP_FAILURE,
                                storeKey: action.storeKey || reqAction,
                                reqType: 'PATCH',
                                error,
                                res,
                            });
                        }

                        // Once data is received, dispatch an action telling the application
                        // that data was received successfully, along with the parsed data
                        next({

                            type: HTTP_SUCCESS,
                            storeKey: action.storeKey || reqAction,
                            reqType: 'PATCH',
                            data: res.body,
                            reqObject,

                        });
                    });
            }
            break;

        case POST_HTTP:
            if (true) {
                const {
                    reqAction,
                    reqObject,
                    reqObject2,
                    reqQuery,
                    reqBody,
                } = action.requestData;

                // If we have no object, lets set pending to true
                if (!reqObject) {
                    next({
                        type: HTTP_PENDING,
                        storeKey: action.storeKey || reqAction,
                    });
                }

                // let's build the url string
                const url = createUrl(action);

                // DEV-1756: added returnPromise to flag returning a promise from HTTP POST
                if (action?.requestData?.returnPromise) {
                    return request
                        .post(url)
                        .withCredentials()
                        .set('Authorization', JWT)
                        .use(async (req) => {
                            var tokenUpdated = await app.shouldUpdateToken();
                            if(tokenUpdated){
                                console.log("[[INFO]http.request] token has been updated for: PUT_HTTP")
                                req.set('Authorization', `bearer ${app.token.get('token')}`);
                            }
                          })
                        .send(reqBody)
                }

                request
                    .post(url)
                    .withCredentials()
                    .set('Authorization', JWT)
                    .use(async (req) => {
                        var tokenUpdated = await app.shouldUpdateToken();
                        if(tokenUpdated){
                            console.log("[[INFO]http.request] token has been updated for: POST_HTTP")
                            req.set('Authorization', `bearer ${app.token.get('token')}`);
                        }
                      })
                    .send(reqBody)
                    .end((err, res) => {
                        if (err) {
                            verifyAuthenticated(err);

                            // TODO: Fix once no longer routing SMS HTTP separately. HACK!!!!
                            if (
                                action.namespace == 'sms' &&
                                reqAction == 'send'
                            ) {
                                errorNotification({
                                    title: 'Error sending SMS',
                                    message: `To: ${reqBody.to}`,
                                })

                                next({
                                    type: SMS_SEND_FAILURE,
                                    error: err,
                                    action,
                                    reqType: 'POST',
                                });
                            }

                            // in case there is any error, dispatch an action containing the error
                            return next({
                                type: HTTP_FAILURE,
                                storeKey: action.storeKey || reqAction,
                                error: err,
                                res,
                                reqType: 'POST',
                            });
                        }

                        // TODO: Fix once no longer routing SMS HTTP separately. HACK!!!!
                        if (action.namespace == 'sms' && reqAction == 'send') {
                            next({
                                type: SMS_SEND_SUCCESS,
                                error: err,
                                action,
                            });
                        }

                        // Once data is received, dispatch an action telling the application
                        // that data was received successfully, along with the parsed data
                        next({
                            type: HTTP_SUCCESS,
                            storeKey: action.storeKey || reqAction,
                            data: res.body,
                            reqObject,
                            reqType: 'POST',
                        });
                    });
            }
            break;

        case PUT_HTTP:
            if (true) {
                const {
                    reqAction,
                    reqObject,
                    reqObject2,
                    reqQuery,
                    reqBody,
                } = action.requestData;

                // If we have no object, lets set pending to true
                if (!reqObject) {
                    next({
                        type: HTTP_PENDING,
                        storeKey: action.storeKey || reqAction,
                    });
                }

                // let's build the url string
                const url = createUrl(action);

                console.log(">>> http action=", action);

                // DEV-1756: added returnPromise to flag returning a promise from HTTP PUT
                if (action?.requestData?.returnPromise) {
                    return request
                        .put(url)
                        .withCredentials()
                        .set('Authorization', JWT)
                        .use(async (req) => {
                            var tokenUpdated = await app.shouldUpdateToken();
                            if(tokenUpdated){
                                console.log("[[INFO]http.request] token has been updated for: PUT_HTTP")
                                req.set('Authorization', `bearer ${app.token.get('token')}`);
                            }
                          })
                        .send(reqBody)
                }



                request
                    .put(url)
                    .withCredentials()
                    .set('Authorization', JWT)
                    .use(async (req) => {
                        var tokenUpdated = await app.shouldUpdateToken();
                        if(tokenUpdated){
                            console.log("[[INFO]http.request] token has been updated for: PUT_HTTP")
                            req.set('Authorization', `bearer ${app.token.get('token')}`);
                        }
                      })
                    .send(reqBody)
                    .end((error, res) => {
                        if (error) {
                            verifyAuthenticated(error);

                            // in case there is any error, dispatch an action containing the error
                            return next({
                                type: HTTP_FAILURE,
                                reqType: 'PUT',
                                storeKey: action.storeKey || reqAction,
                                error,
                                res,
                            });
                        }

                        // Once data is received, dispatch an action telling the application
                        // that data was received successfully, along with the parsed data
                        next({
                            type: HTTP_SUCCESS,
                            storeKey: action.storeKey || reqAction,
                            reqType: 'PUT',
                            data: res.body,
                            reqObject,
                        });
                    });
            }
            break;

        case DELETE_HTTP:
            if (true) {
                const {
                    reqAction,
                    reqObject,
                    reqObject2,
                    reqQuery,
                    reqBody,
                } = action.requestData;

                // If we have no object, lets set pending to true
                if (!reqObject) {
                    next({
                        type: HTTP_PENDING,
                        storeKey: action.storeKey || reqAction,
                    });
                }

                // let's build the url string
                const url = createUrl(action);

                request
                    .delete(url)
                    .send(reqBody)
                    .set('Authorization', JWT)
                    .withCredentials()
                    .use(async (req) => {
                        var tokenUpdated = await app.shouldUpdateToken();
                        if(tokenUpdated){
                            console.log("[[INFO]http.request] token has been updated for: DELETE_HTTP")
                            req.set('Authorization', `bearer ${app.token.get('token')}`);
                        }
                      })
                    .end((error, res) => {
                        if (error) {
                            verifyAuthenticated(error);

                            // in case there is any error, dispatch an action containing the error
                            return next({
                                type: HTTP_FAILURE,
                                storeKey: action.storeKey || reqAction,
                                reqType: 'DELETE',
                                error,
                                res,
                            });
                        }

                        // Once data is received, dispatch an action telling the application
                        // that data was received successfully, along with the parsed data
                        next({
                            type: HTTP_SUCCESS,
                            storeKey: action.storeKey || reqAction,
                            reqType: 'DELETE',
                            data: res.body,
                            reqObject,
                        });
                    });
            }
            break;
        // Do nothing if the action does not interest us
        default:
            break;
    }
};

const verifyAuthenticated = error => {
    if (error && error.status && error.status == 401) {
        app.showLogin();
    }
};

const createUrl = action => {

    let rootUrl = '/api/v1';
    let reportsUrl;

    if (window.origin.includes('beta') || window.origin.includes('staging')) {
        reportsUrl = 'https://beta-reports.fluentcloud.com/v2';
    } else {
        reportsUrl = 'https://reports.fluentcloud.com/v2';
    }

    if (window.BASE_URL) {
        rootUrl = `${window.BASE_URL}/api/v1`;
    }

    //Change tenant and extension here if working in localdev 
    const tenant = app.token.get('tenant');
    const extension = app.token.get('extension');

    const {
        auditHistory,
        reqAction,
        reqObject,
        reqBody,
        reqObject2,
        reqObject3,
        reqQuery,
        reportPage,
    } = action.requestData;
    const page = action.page;

    // Handle sms send differently (grrrrr)
    if (action.namespace == SMS_NAMESPACE && reqAction == 'send') {
        console.log('Sending SMS for tenant: ', tenant);
        return `${rootUrl}/${action.namespace}/${reqAction}/${tenant}`;
    }

    // We need to add the left bar 'extension' here, instead of our
    // localStorage extension, OR token extension. This
    //is a temporary fix to allow for our left bar 'extension'
    if ((action.type === "GET_HTTP" && action.namespace === 'userportal' && action.storeKey === "voicemailsettings") ||
        (action.type === "GET_HTTP" && action.namespace === 'userportal' && action.storeKey === "voicemailgreetings") ||
        (action.type === "GET_HTTP" && action.namespace === 'userportal' && action.storeKey === "voicemailmessages")) {
        return `${rootUrl}/${action.namespace}/${tenant}/${reqObject}/${reqAction}`;
    }

    if ((action.type === "DELETE_HTTP" && action.storeKey === 'voicemailgreetings') ||
        (action.type === "POST_HTTP" && action.storeKey === 'voicemailgreetings') ||
        (action.type === "PATCH_HTTP" && action.requestData.reqAction === 'voicemailmessagesbatch')) {
        return `${rootUrl}/${action.namespace}/${tenant}${reqObject2 ? `/${reqObject2}` : ''}/${reqAction}/${reqObject}`;
    }

    if (action.storeKey === 'recordings' && action.type === "POST_HTTP") {
        return `${rootUrl}/${action.namespace}/${tenant}${reqObject2 ? `/${reqObject2}` : ''}/${reqAction}/${reqObject}`;
    }
    if (action.type === "PUT_HTTP" && action.storeKey === "voicemailsettings") {
        return `${rootUrl}/${action.namespace}/${tenant}${reqObject2 ? `/${reqObject2}` : ''}/${reqObject}/${reqAction}`;
    }

    const token = app.token.attributes;
    let timeZone = '';

    if (token.timezone && token.timezone.length) {

        timeZone = `&timezone=${token.timezone}`;

    } else {

        timeZone = '&timezone=US/Mountain';
    }

    switch (action.namespace) {
        case USERPORTAL_NAMESPACE:
            return `${rootUrl}/${action.namespace}/${tenant}/${extension}${
                reqAction ? `/${reqAction}` : ''
                }${reqObject ? `/${reqObject}` : ''}${
                reqObject2 ? `/${reqObject2}` : ''
                }?${reqQuery ? `${reqQuery}` : ''}${
                auditHistory ? '&audithistory=true' : ''
                }`;
            break;
        case CORE_NAMESPACE:
        case CALL_NAMESPACE:
        case MASTER_NAMESPACE:
            return `${rootUrl}/${action.namespace}/${tenant}${
                reqAction ? `/${reqAction}` : ''
                }${reqObject ? `/${reqObject}` : ''}${
                reqObject2 ? `/${reqObject2}` : ''
                }?${reqQuery ? `${reqQuery}` : ''}${
                reportPage ? `&page=${reportPage}` : ''
                }${auditHistory ? '&audithistory=true' : ''}`;
            break;
        case REPORTS_NAMESPACE:
            return `${rootUrl}/reports/${tenant}/${reqAction}?${reqQuery}`;
            break;
        case REPORTS_NAMESPACE_V2:
            return `${reportsUrl}/${action.namespace}/${tenant}/${reqObject3 ? reqObject3 : extension}${
                reqAction ? `/${reqAction}` : ''
                }${reqObject ? `/${reqObject}` : ''}${
                reqObject2 ? `/${reqObject2}` : ''
                }?${reqQuery ? `${reqQuery}` : ''}${timeZone}${
                reportPage ? `&page=${reportPage}` : ''
                }${auditHistory ? '&audithistory=true' : ''}`;
            break;
        case SMS_NAMESPACE:
            if (page) {
                return `${rootUrl}/${action.namespace}/${tenant}${
                    reqAction ? `/${reqAction}` : ''
                    }${reqObject ? `/${reqObject}` : ''}${
                    reqObject2 ? `/${reqObject2}` : ''
                    }?${reqQuery ? `${reqQuery}` : ''}&page=${page}${
                    auditHistory ? '&audithistory=true' : ''
                    }`;
            } else {
                return `${rootUrl}/${action.namespace}/${tenant}${
                    reqAction ? `/${reqAction}` : ''
                    }${reqObject ? `/${reqObject}` : ''}${
                    reqObject2 ? `/${reqObject2}` : ''
                    }?${reqQuery ? `${reqQuery}` : ''}${
                    auditHistory ? '&audithistory=true' : ''
                    }`;
            }
            break;
    }
};

const defaultHeaders = {
    accept: 'application/json, text/javascript, */*; q=0.01',
    'accept-encoding': 'gzip, deflate, sdch, br',
    Connection: 'keep-alive',
    'Content-Type': 'application/json',
};

export default fluentcloudAPI;
